import React, { useEffect } from "react";
import Card from "./Card";

import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import SectionTitle from "./SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { relatedProductsFetching } from "../features/products/relatedProductSlice";
import { useParams } from "react-router-dom";

const RelatedProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items: relatedProduct } = useSelector(
    (state) => state.relatedProducts
  );

  useEffect(() => {
    dispatch(relatedProductsFetching(id));
  }, [id, dispatch]);
  return (
    <>
      {relatedProduct?.length > 0 && (
        <div key={id} className="">
          <div className="my-5">
            <SectionTitle title={"Related Product"} />
          </div>
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              HashNavigation,
              Grid,
            ]}
            spaceBetween={10}
            // slidesPerView={6}
            // navigation
            hashNavigation
            className="w-full h-full"
            scrollbar={{ draggable: true }}
            breakpoints={{
              425: { slidesPerView: 2 },

              768: {
                slidesPerView: 2,
                spaceBetweenSlides: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
          >
            <div>
              {relatedProduct?.map((product) => (
                <SwiperSlide>
                  <Card key={id} product={product} />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      )}
    </>
  );
};

export default RelatedProduct;
