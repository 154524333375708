import React from "react";
import SectionTitle from "../components/SectionTitle";

const SupportPolicy = () => {
  return (
    <div className="container mx-auto px-3">
      <SectionTitle title={"Support Policy"} />
      {/* <div className="support_policy">
        <div className="general">
          <p className="mb-2 md:text-[18px]">
            <br />
            We are available to support you 24 hours for your valuable any
            query.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default SupportPolicy;
