import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

//data fetching

export const offerProductsFetching = createAsyncThunk(
  "offerProducts/offerProductsFetching",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-offer`);
    // https://www.asponlinemarket.com/admin/public/api-offer
    return res.data;
  }
);

export const offerProductsSlice = createSlice({
  name: "offerProducts/offerProductsFetching",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(offerProductsFetching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(offerProductsFetching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(offerProductsFetching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default offerProductsSlice.reducer;
