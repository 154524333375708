import React from "react";
import SectionTitle from "../components/SectionTitle";

const ReturnPolicy = () => {
  return (
    <div className="container mx-auto px-3">
      <SectionTitle title={"Return Policy"} />
      {/* <div className="return_policy">
        <div className="general">
          <p className="mb-2 md:text-[18px]">
            <br />
            If your product is damaged, defective, incorrect or incomplete at
            the time of delivery, please raise a return request on ASP Online
            Market website. Return request must be raised within 14 days for ASP
            items, or within 7 days for non-ASP items from the date of delivery.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ReturnPolicy;
