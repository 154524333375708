import React from "react";

import SectionTitle from "../components/SectionTitle";

const Contact = () => {
  return (
    <div className="container mx-auto px-3">
      <SectionTitle title={"Contact"} />
      <div className="contact">
        <div className="general">
          <p className="mb-2 md:text-[18px]">
            Contact info <br />
            Baltao , Taytay , Philippine <br />
            Phone: +639562254481
            <br />
            Email:
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
