import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

//data fetching

export const relatedProductsFetching = createAsyncThunk(
  "relatedProducts/relatedProductsFetching",
  async (productId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/relatedproductdata/${productId}`
    );
    return res.data;
  }
);

export const relatedProductsSlice = createSlice({
  name: "relatedProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(relatedProductsFetching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(relatedProductsFetching.fulfilled, (state, action) => {
      state.status = "success";
      state.items = action.payload;
    });
    builder.addCase(relatedProductsFetching.rejected, (state, action) => {
      // state.status = "Failed";
      state.error = action.error.message;
    });
  },
});

export default relatedProductsSlice.reducer;
