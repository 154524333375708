import { currencyFormatter } from "../utils/currencyFormatter";
// import { useDispatch } from "react-redux";
// import {
//   addToSingleCart,
//   removeAllFromCart,
// } from "../features/products/cartSlice";
import { Link } from "react-router-dom";
// import { useState } from "react";
const Card = ({ product }) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [colorAtr] = useState(null);
  // const [sizeAtr] = useState(null);
  // const addToCartHandler = (product) => {
  //   dispatch(addtoCart(product));
  //   navigate("/checkout");
  // };

  // const [cartQuantity] = useState(1);

  // const handleBuyNow = () => {
  //   dispatch(removeAllFromCart());
  //   dispatch(addToSingleCart({ ...product, cartQuantity, colorAtr, sizeAtr }));
  //   navigate("/checkout");
  // };
  return (
    <Link
      className="flex flex-col gap-4 border-2 p-3  w-full hover:border-[#2196F3] duration-300"
      to={`/productdetails/${product?.id}`}
    >
      <div className=" md:h-[15rem] h-[12rem] overflow-hidden">
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="product-details flex flex-col justify-center gap-2.5">
        <p className="font-semibold hover:text-[#2196F3] truncate duration-300 ">
          {product?.name}
        </p>
        <span className="text-base  flex gap-2">
          {product?.discount > 0 ? (
            <>
              <span className="font-semibold line-through text-[#EE9B27] decoration-200">
                {product?.unit_price}
              </span>
              <span className="font-semibold text-[#EE9B27] text-xl">
                {product?.after_discount}
              </span>
            </>
          ) : (
            <span className="font-semibold text-[#EE9B27] text-xl">
              {product?.unit_price}
            </span>
          )}
        </span>
        <Link
          // onClick={handleBuyNow}
          to={`/productdetails/${product?.id}`}
          className=" w-full bg-[#2196F3] inline-block text-center rounded-full group-hover:bg-[#2196F3] duration-300 py-2 px-5 text-xs  font-semibold"
        >
          View Details
        </Link>
      </div>
    </Link>
  );
};

export default Card;
