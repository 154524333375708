import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addtoCart,
  decreaseCart,
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  // const { items: shippingCharge } = useSelector(
  //   (state) => state.shippingCharge
  // );

  const shippingCharge = [
    { id: 1, name: "Inside Dhaka", amount: 60 },
    { id: 2, name: "Outside Dhaka", amount: 120 },
  ];
  // const [orderItem, setOrderItem] = useState(data);
  const [response, setResponse] = useState(0);

  // console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
    coupon_discount: 0,
  });
  const [couponCode, setCouponCode] = useState("");

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleDecrease = (product) => {
    if (product?.cartQuantity > 1) {
      dispatch(decreaseCart(product));
    }
  };

  const handleIncrease = (product) => {
    dispatch(addtoCart(product));
  };

  // const handleRemove = (product) => {
  //   dispatch(removeFromCart(product));
  // };
  const applyCoupon = async () => {
    setCouponCode("");
    try {
      await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: couponCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(data);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  let grand_total = response.discount
    ? cartTotalAmount + +formData.shipping_cost + -response.discount
    : cartTotalAmount + +formData.shipping_cost + 0;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: data,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        // item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };

  return (
    <>
      <div className="checkout-page mt-10 mb-10 mx-4 grid grid-cols-1 md:grid-cols-2 gap-20">
        <form
          onSubmit={handleFormSubmit}
          className="billing-address border border-black bg-white"
        >
          <div className="billing-address flex flex-col gap-2 order-2 md:order-1 p-5">
            <div className="flex items-center justify-center bg-[#2196F3] rounded text-white">
              {/* <Marquee direction="right" className=""> */}
              <h2 className="text-2xl py-4">
                Fill out the form below to order...
              </h2>
              {/* </Marquee> */}
            </div>
            <div className="form-control flex flex-col gap-2 mt-2">
              <label className="">Your Name</label>
              <input
                required
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                value={formData.name}
                className="px-3 py-2  rounded-md outline-none border focus:border-[#2196F3] duration-300"
                placeholder="Enter your name"
              />
            </div>
            <div className="form-control flex flex-col my-2">
              <label className="">Entar Your Phone Number</label>
              <input
                required
                type="tel"
                pattern="[0-9]{11}"
                id="number"
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                // onChange={handleChange}
                value={formData.phone}
                className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#2196F3] duration-300"
                placeholder="Enter Your Number"
              />
            </div>
            <div className="form-control flex flex-col  mb-3 ">
              <label className="mb-2">Your Address</label>
              <textarea
                required
                type="text"
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                value={formData.address}
                className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#2196F3] duration-300 resize-none"
                placeholder="Enter your name"
              />
            </div>
            {/* <div className="form-control flex flex-col  mb-3 ">
            <label className="mb-2">Shipping Cost</label>
            <textarea
              required
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, shipping_cost: e.target.value })
              }
              value={formData.shipping_cost}
              className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#F4891F] duration-300 resize-none"
              placeholder="Enter Your shipping cost"
            />
          </div> */}
            <div className="form-control flex flex-col gap-2  mb-3">
              <select
                onChange={(e) =>
                  setFormData({ ...formData, shipping_cost: e.target.value })
                }
                className="px-3 py-2  rounded-md outline-none border focus:border-[#2196F3] duration-300"
              >
                <option value="0">Select Area</option>
                {shippingCharge?.map((charge) => (
                  <option key={charge.id} value={+charge?.amount}>
                    {charge?.name} ({charge?.amount})
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control flex items-center justify-center">
              <button
                type="submit"
                className="bg-[#2196F3] px-3 py-2 rounded-md text-lg text-white   hover:bg-[#345e80] duration-300"
              >
                Confirm Order
              </button>
            </div>
          </div>
        </form>

        <div className="order-details  md:order-2 order-1 border border-black bg-white p-5">
          <div className="flex justify-center mb-5 underline underline-offset-8 text-[#2196F3]">
            <h2 className="text-2xl">Your Order</h2>
          </div>

          <div className="h-auto overflow-y-scroll order-details-container">
            {data?.map((product) => (
              <div
                key={product.id}
                className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
              >
                <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                    className="w-full object-cover "
                    alt=""
                  />
                </div>
                <div className="cart-description w-full overflow-hidden ">
                  <div className="flex flex-col">
                    <p className="truncate">
                      {product?.name?.substring(0, 60)}...
                    </p>
                    <div className="flex gap-5 items-center my-3">
                      {/* <div className="cart-price">
                        {product?.unit_price * product?.cartQuantity} ৳
                      </div> */}

                      <div className="col-unit-price">
                        {product?.after_discount > 0 ? (
                          <span className="flex gap-2">
                            {/* <span></span> */}
                            <span>
                              {+product?.after_discount * product?.cartQuantity}
                            </span>
                          </span>
                        ) : (
                          <span className="flex">
                            {/* <span>৳</span> */}
                            <span>
                              {+product?.unit_price * product?.cartQuantity}
                            </span>
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="">
                          <div className="counter flex">
                            <button
                              onClick={() => handleDecrease(product)}
                              className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50"
                            >
                              -
                            </button>
                            <span className="h-10 w-10 bg-gray-100 flex justify-center items-center border border-gray-300">
                              {product?.cartQuantity}
                            </span>
                            <button
                              onClick={() => handleIncrease(product)}
                              className="h-10 w-10 bg-gray-100 border border-gray-300 active:bg-gray-700 active:text-gray-50"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <button
                      className="text-base hover:text-red-500 duration-500 justify-center"
                      onClick={() => handleRemove(product)}
                    >
                      <RiDeleteBin6Line />
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="flex flex-col gap-3 my-3">
              <input
                type="text"
                value={couponCode}
                onChange={handleCouponCodeChange}
                className="px-3 py-2 w-full rounded-md outline-none border border-[#2196F3] focus:border-[#2196F3] duration-300 resize-none"
              />
              <div>
                <button
                  onClick={applyCoupon}
                  className="bg-[#0ea5e9] px-3 py-2  text-sm rounded-md"
                >
                  Apply Coupon
                </button>
              </div>
            </div>
            {response.status === 200 && (
              <p className="text-green-500 text-xs mt-2">{response.massage}</p>
            )}
            {response.status === 404 && (
              <p className="text-rose-500 text-xs mt-2">{response.massage}</p>
            )}
          </div>
          <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 "></div>
          <div className="payment-summary flex flex-col gap-3 mt-5 ">
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">
                Sub Total
              </h4>
              <span className="text-slate-700 ">{cartTotalAmount} </span>
            </div>
            <div className="shipping flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold ">
                Shipping
              </h4>
              <span className="text-slate-700 ">{+formData.shipping_cost}</span>
            </div>
            <div className="shipping flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold ">
                Discount
              </h4>
              <span className="text-slate-700 ">
                {response.discount ? +response.discount : 0}
              </span>
            </div>
            <div className="bg-black h-1"></div>
            <div className="sub-total flex justify-between">
              <h4 className="text-lg text-slate-400 font-semibold">Total</h4>
              <span className="text-slate-700 font-semibold ">
                {grand_total}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
