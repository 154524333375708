import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";

const CategorySection = ({
  handleOpen,
  getSubcategoriesByCategoryId,
  subcategories,
  category,
  open,
}) => {
  return (
    <Card className="category-thumb  overflow-y-scroll h-full w-full   p-4 shadow-xl shadow-blue-gray-900/5 rounded-none">
      <div className="flex flex-col gap-2 mb-4">
        <Link to={`/wholesaleproducts`}>Wholesale</Link>
        <Link
          to={`/offerproducts`}
          className="text-2xl text-[#EE9B27] font-semibold"
        >
          flash sale
        </Link>
        {/* <Link
          to={`${process.env.REACT_APP_URL}/user/register`}
          // to={`https://www.asponlinemarket.com/admin/public/user/register`}
          rel="noreferrer"
        >
          Dealer
        </Link>
        <Link
          to={`${process.env.REACT_APP_URL}/seller/register`}
          rel="noreferrer"
        >
          Seller
        </Link> */}
      </div>

      <div className="">
        <Typography variant="h5" color="blue-gray">
          Categories
        </Typography>
      </div>
      <List>
        {category?.map((category, index) => (
          <Accordion
            key={index}
            open={open === index}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === index ? "rotate-180" : ""
                }`}
              />
            }
          >
            <ListItem className="p-0" selected={open === 1}>
              <AccordionHeader
                onClick={() => handleOpen(index)}
                className="border-b-0 py-3"
              >
                <ListItemPrefix className="h-5 w-5 overflow-hidden rounded">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/category/${category?.icon}`}
                    alt={category.alt}
                    className="w-full h-full object-cover"
                  />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-normal">
                  {category?.name}
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                {getSubcategoriesByCategoryId(category?.id).map((subCat) => (
                  <Link to={`subcategory/${subCat.id}`} key={subCat?.id}>
                    <ListItem>
                      <ListItemPrefix className="h-3 w-5">
                        <ChevronRightIcon strokeWidth={3} />
                        {/* <img
                        src={`${process.env.REACT_APP_URL}/uploads/subcategory/${subCat.banner}`}
                        alt=""
                      /> */}
                      </ListItemPrefix>
                      {subCat?.name}
                    </ListItem>
                  </Link>
                ))}
              </List>
            </AccordionBody>
          </Accordion>
        ))}
      </List>
    </Card>
  );
};

export default CategorySection;
