import axios from "axios";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { MdImageSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../utils/currencyFormatter";

const Search = () => {
  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  console.log(word);
  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose = () => {
    setWord();
    setFilterData([]);
  };

  return (
    <div className=" bg-gray-400/10 fixed   border top-10  rounded-md overflow-hidden">
      <div className="search-container flex flex-col    md:w-[20rem] lg:w-[30rem] items-center justify-center">
        <div className="flex items-center gap-2 w-full relative z-[9999] ">
          <input
            onChange={handleChange}
            type="text"
            className="focus:outline-none p-3 w-full  bg-white"
            placeholder="Search Here..."
          />
          {filterData.length === 0 ? (
            <div className="flex gap-2 mx-3">
              <span>
                <FiSearch className="text-xl text-gray-500" />
              </span>
              <span>
                <MdImageSearch className="text-xl text-gray-500" />
              </span>
            </div>
          ) : (
            <IoIosClose
              onClick={handleClose}
              className="absolute right-3  text-2xl text-gray-500"
            />
          )}
        </div>
        {filterData.length !== 0 && (
          <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
            {filterData.slice(0, 5).map((product) => (
              <Link
                onClick={handleClose}
                key={product.id}
                to={`/productdetails/${product.id}`}
                className="product-card flex items-center    gap-2"
              >
                <div className="product-image w-20 h-20 overflow-hidden">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="product-content text-black w-full text-sm overflow-hidden">
                  <h4 className="font-semibold">{product?.brand_name}</h4>
                  <p className="truncate text-lg ">{product?.name}</p>
                  <span className="text-gray-400">
                    {currencyFormatter(product?.unit_price)}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
