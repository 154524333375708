import { Carousel } from "react-responsive-carousel";
// import { Swiper, SwiperSlide } from "swiper/react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductImageCarasol = ({ thumbImage }) => {
  // const [image, setImage] = useState(singleProduct);
  // console.log(image);

  // const chnageImage = () => {
  //   setImage(`${process.env.REACT_APP_URL}/uploads/product/${image.icon}`);
  // };

  return (
    <div className="w-auto overflow-hidden">
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={60}
        infiniteLoop={true}
        showArrows={true}
        showThumbs={true}
        className="productCarousel w-full h-full "
      >
        {/* <div className="h-20 w-20 overflow-hidden">
          <img src={productImg} alt="" className="w-full h-full object-cover" />
        </div> */}

        {thumbImage?.map((image) => (
          <div key={image?.id} className=" w-full h-[30rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
              alt="thamb pic"
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductImageCarasol;
