import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Parser } from "html-to-react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";

import { BsMessenger, BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";

import { toast } from "react-toastify";

import { IoLogoWhatsapp } from "react-icons/io";
// import ImageComp from "../components/ImageComp";
// import { Carousel } from "react-responsive-carousel";
import { currencyFormatter } from "../utils/currencyFormatter";

// import { AiOutlineLoading } from "react-icons/ai";

// import ProductImageCarasol from "../components/ProductImageCarasol";
import { singleProductFetching } from "../features/products/singleProductSlice";
import { thumbImageFetching } from "../features/products/thumbImageSlice";

import Loader from "../components/Loader";
import { Carousel } from "react-responsive-carousel";
import ProductImageCarasol from "../components/ProductImageCarasol";
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { items: singleProduct, status } = useSelector(
    (state) => state.singleProduct
  );
  const { items: thumbImage } = useSelector((state) => state.thumbImage);

  const [response, setResponse] = useState({});
  // const [singleProduct, setSingleProduct] = useState({});
  const [cartQuantity, setCartQuantity] = useState(1);
  // const [modalVisible, setModalVisible] = useState(false);
  // const [imageThamb, setImageThamb] = useState([]);

  const [size, setSize] = useState([]);

  // const [colorAtr, setColorAtr] = useState(null);
  // const [sizeAtr, setSizeAtr] = useState(null);
  // const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const singleProduct = async () => {
  //     // setIsLoading(true);
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_URL}/api-products/${id}`
  //     );
  //     // setIsLoading(false);
  //     return setSingleProduct(res.data);
  //   };
  //   singleProduct();
  // }, [id]);

  useEffect(() => {
    dispatch(singleProductFetching(id));
    dispatch(thumbImageFetching(id));
  }, [dispatch, id]);

  // const handleSizeChange = (event) => {
  //   setSizeAtr(event.target.value);
  // };

  // const handleModalOpen = () => {
  //   setModal(!modal);
  // };

  // const handleClose = () => {
  //   setModal(!modal);
  // };

  useEffect(() => {
    // const colors = async () => {
    //   const res = await axios.get(
    //     `${process.env.REACT_APP_URL}/api-color/${singleProduct?.product?.color}`
    //   );

    //   return setColor(res?.data);
    // };
    // colors();
    const sizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${id}`
      );

      return setSize(res?.data);
    };
    sizes();
  }, [id]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  const handleAddToCart = () => {
    if (selectedOption) {
      if (
        response?.minqty <= cartQuantity &&
        cartQuantity <= response?.maxqty
      ) {
        dispatch(removeAllFromCart());
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            // colorAtr,
            // sizeAtr,
            sizeAtr: selectedOption,
            cartQuantity,
            response: response?.price,
          })
        );
      } else {
        toast.warn(
          `Put min-qty ${response?.minqty}   ${
            !response?.maxqty ? "" : `& max-qty ${response?.maxqty}`
          }`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } else if (!selectedOption) {
      // toast.warn(`Please Select the options`, {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      if (cartQuantity >= singleProduct?.product?.min_qty) {
        // dispatch(removeAllFromCart());
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            // colorAtr,
            // sizeAtr,
            sizeAtr: selectedOption,
            cartQuantity,
            attributePrice: response?.price,
          })
        );
      } else {
        toast.warn(
          `Minimum Quantity Atleast ${singleProduct?.product?.min_qty} ${singleProduct?.product?.unit}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };
  const handleBuyNow = () => {
    if (selectedOption) {
      if (
        response?.minqty <= cartQuantity &&
        cartQuantity <= response?.maxqty
      ) {
        dispatch(removeAllFromCart());
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            // colorAtr,
            // sizeAtr,
            sizeAtr: selectedOption,
            cartQuantity,
            response: response?.price,
          })
        );
        navigate("/checkout");
      } else {
        toast.warn(
          `Put min-qty ${response?.minqty}   ${
            !response?.maxqty ? "" : `& max-qty ${response?.maxqty}`
          }`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } else if (!selectedOption) {
      // toast.warn(`Please Select the options`, {
      //   position: "bottom-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
      if (cartQuantity >= singleProduct?.product?.min_qty) {
        // dispatch(removeAllFromCart());
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            // colorAtr,
            // sizeAtr,
            sizeAtr: selectedOption,
            cartQuantity,
            attributePrice: response?.price,
          })
        );
        navigate("/checkout");
      } else {
        toast.warn(
          `Minimum Quantity Atleast ${singleProduct?.product?.min_qty} ${singleProduct?.product?.unit}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  };

  const htmlParser = new Parser();

  // {modalVisible && (
  //   <>
  //
  //   </>
  // )}

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: singleProduct?.product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, singleProduct?.product?.id]);

  const handleRadioClick = (opstion) => {
    setCartQuantity(1);
    setSelectedOption(opstion);
  };

  const handlePrice = (e) => {
    setCartQuantity(e.target.value);
  };
  return (
    <>
      <div className="mt-8 p-3 md:p-6 ">
        {status === "idle " && <Loader />}
        <div className="grid lg:grid-cols-[45%_55%] gap-5">
          <div className="left image ">
            <div className="w-full h-auto overflow-hidden">
              <ProductImageCarasol
                thumbImage={thumbImage}
                className="w-full h-full object-cover"
              />
              <div></div>
            </div>
          </div>
          <div className="right flex flex-col gap-5">
            <div className="font-semibold text-lg lg:text-3xl">
              {singleProduct?.product?.name}
            </div>

            <div></div>

            {response.status === 200 ? (
              <span className="text-2xl font-semibold text-[#F4891F]">
                {response?.price} /
                <span className="text-sm">{singleProduct?.product?.unit}</span>
              </span>
            ) : (
              <p className="text-2xl font-semibold text-[#F4891F]">
                {singleProduct?.product?.unit_price}
                {singleProduct?.product?.unit && (
                  <span className="text-sm">
                    / {singleProduct?.product?.unit}
                  </span>
                )}
              </p>
            )}

            {selectedOption ? (
              <div>
                <span className="text-xl inline-block mb-5 font-semibold">
                  Select Options
                </span>
                <div className="flex gap-5 flex-wrap">
                  {size?.map((val, index) => (
                    <div className="flex flex-col" key={index}>
                      <span
                        onClick={() => handleRadioClick(val.variant)}
                        className={`border border-black cursor-pointer rounded-md py-3 px-4  ${
                          selectedOption === val.variant
                            ? "bg-red-600 border-red-600 text-white"
                            : ""
                        }`}
                      >
                        {val.variant}
                      </span>

                      <p
                        className={`flex justify-center mt-2 ${
                          selectedOption === val.variant
                            ? "text-red-600"
                            : "text-[#F4891F]"
                        } text-xl font-semibold`}
                      >
                        {val.price}
                      </p>
                    </div>
                  ))}
                </div>

                <div className=""></div>
              </div>
            ) : (
              ""
            )}
            <div className="flex gap-3">
              <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                Quantity :
              </span>
              <div className="flex items-center gap-4 ">
                <button
                  className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#F4891F] hover:text-white duration-500"
                  onClick={() => handleDecrease(singleProduct?.product)}
                >
                  <AiOutlineMinus />
                </button>
                <input
                  type="number"
                  value={cartQuantity}
                  onChange={handlePrice}
                  className="w-20 text-center "
                />

                <button
                  className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#F4891F] hover:text-white duration-500"
                  onClick={() => handleIncrease(singleProduct?.product)}
                >
                  <BsPlus />
                </button>
              </div>
            </div>

            {!selectedOption && (
              <span className="text-[#EE9B27] font-semibold">
                Minimum Quantity: {singleProduct?.product?.min_qty}
              </span>
            )}

            <div className="stoke-status  text-[#16995D]">
              {singleProduct?.product?.current_stock > 0 && (
                <span className="text-[1.4rem]">In stock</span>
              )}
            </div>
            <div className="stoke-status mt-1 text-[#EE9B27]">
              {singleProduct?.product?.current_stock <= 0 && (
                <span className="text-[2rem]">Out of Stock</span>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2 text-white">
              <div className="col-span-2 flex gap-2">
                <button
                  onClick={handleBuyNow}
                  // disabled={cartQuantity < singleProduct?.product?.min_qty}
                  className="w-full py-2  px-5 bg-[#2196F3] rounded flex items-center justify-center"
                >
                  <span className="text-xl">Order Now</span>
                </button>

                <button
                  onClick={handleAddToCart}
                  className="w-full py-2 px-5 bg-[#EE9B27] rounded flex items-center justify-center"
                >
                  Add To Cart
                </button>
              </div>

              <a
                className="w-full py-2 px-5 bg-[#313131] rounded hover:bg-[#222222] duration-300 col-span-2 flex flex-col items-center justify-center"
                href="tel://+8801773-515030"
              >
                <span>Click to call</span>
                <span>+8801773-515030</span>
              </a>

              <Link
                // to={`https://m.me/ttckitchenware`}
                target="_blank"
                rel="noreferrer"
                className="w-full py-2 px-5 bg-[#00A0F7] rounded hover:bg-[#0080f7] duration-300 col-span-2 flex flex-col items-center justify-center"
              >
                <span>Message for details</span>
                <span className="text-xl">
                  <BsMessenger />
                </span>
              </Link>

              <Link
                to={`https://wa.me/+8801773-515030`}
                target="_blank"
                rel="noreferrer"
                className="w-full py-2 px-5 bg-[#00E676] rounded hover:bg-[#1C753B] duration-300 col-span-2 flex flex-col items-center justify-center"
              >
                <span>WhatsApp</span>
                <span className="text-2xl">
                  <IoLogoWhatsapp />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="my-5">
            <SectionTitle title={"Discription"} />
          </div>

          <div className="description w-[100%] overflow-x-scroll">
            <span>{htmlParser.parse(singleProduct?.product?.description)}</span>
          </div>
        </div>
        <div className="mb-40">
          <RelatedProduct />
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
